import React,{ useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";

import "./App.css";
import { getOrderWithSessionId } from "./services/YNKOApi"


interface PopupOrder{
    id: string;
    first_name: string;
    last_name: string;
    phone: string;
    is_claimed: boolean;
    confirmation_code: string;
}

function Confirmation() {
  const [popupOrder, setPopupOrder] = useState<PopupOrder | undefined>(undefined);
  let [searchParams] = useSearchParams();

    useEffect(()=>{
    const fetchPopup = async () => {
        const fetchPopupResponse = await getOrderWithSessionId({stripeSessionId: searchParams.get("sessioncode")})
        setPopupOrder(fetchPopupResponse.data)
    }
    fetchPopup()
  }, [searchParams])

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignContent: "center",
        alignSelf: "center",
        backgroundColor: "#f0f0f0",
      }}
    >
        <h2>EATJERKNYC</h2>
        <p>Ya Mon, Everything Irie! <span style={{fontStyle: "italic"}}>(We got your order)</span></p>
        <p>Your Confirmation # is: <strong>{popupOrder && popupOrder.confirmation_code}</strong></p>

        <p>Didn't order enough? <br /><a href="/">Order Again</a></p>
    </div>
  );
}

export default Confirmation;
