import React,{ useState, useEffect, useReducer } from "react"
 import { Formik, Form, Field } from 'formik';
 import * as Yup from 'yup';
 import _ from "lodash";

import { getPopUps, createCheckoutSession } from "./services/YNKOApi"
import "./App.css";


interface PopupItem {
  id: number,
  name: string,
  total_qty: number,
  price_cents: number,
  remaining_qty: number
}

interface Popup {
  id: number,
  name: string,
  start_datetime: string,
  venue_name: string,
  venue_url: string,
  items: [PopupItem],
  is_closed: boolean,
}

 const SignupSchema = Yup.object().shape({
   email: Yup.string().email('Invalid email'),
 });

function App() {
  const [email, setEmail] = useState<string | undefined>("");
  const [phone, setPhone] = useState<string | undefined>("");
  const [firstName, setFirstName] = useState<string | undefined>("");
  const [lastName, setLastName] = useState<string | undefined>("");
  const [popups, setPopups] = useState<[Popup] | undefined>(undefined);
  const [subtotal, setSubtotal] = useState<number>(0);

  const cartReducer = (state: any, action: any) => {
    let newState
    switch (action.type) {
        case 'resetFromAPI': {
            const cart = popups ? popups[0].items.reduce((previousValue, popupItem) => {
                const updatedValue: any = previousValue
                updatedValue[popupItem.id] = {
                  qty: 0,
                  price_cents: popupItem.price_cents
                }
                return updatedValue
            }, {}) : {}

            newState = cart
            break
        }

        case 'updateTicketQty': {
            const updatedState = state
            updatedState[action.payload.popupItemId].qty = action.payload.popupItemQty
            newState = { ...updatedState }
            break
        }

        default: {
            throw new Error()
        }
    }
    return newState
  }

  const [cartState, cartDispatch] = useReducer(cartReducer, {})

  useEffect(()=>{
    const fetchPopup = async () => {
      const fetchPopupResponse = await getPopUps()
      setPopups(fetchPopupResponse.data)
      cartDispatch({ type: 'resetFromAPI' })
    }
    fetchPopup()

  }, [])

  useEffect(()=>{
    let subTotalSum: number = 0
    Object.keys(cartState).forEach((cartItemId)=>{
      subTotalSum += cartState[cartItemId].qty * cartState[cartItemId].price_cents
    })
    setSubtotal(subTotalSum)
  }, [cartState])
  
  return (
    <div
      className="App"
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignContent: "center",
        alignSelf: "center",
        backgroundColor: "#f0f0f0",
      }}
    >

        <div style={{ marginBottom: "20px" }}>
          <h2>EATJERKNYC</h2>
            {popups && popups[0] &&
              <>
                <span>presents</span>

                <h1>{popups[0].name.toUpperCase()}</h1>

                <span>{new Date(popups[0].start_datetime).toLocaleString("en-US", {dateStyle: "full", timeStyle: "medium"})}</span>
                <br />
                <span>@ <a href={popups[0].venue_url}>{popups[0].venue_name}</a></span>
              </>
            } 
        </div>
        {popups && !popups[0].is_closed ?
        (<Formik
          initialValues={{
            email: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values)=>{
              const submitObj: any = {
                email,
                phone,
                firstName,
                lastName,
                popup_event_id: popups ? popups[0].id : null,
                cart: cartState
              }
              const stripeSessionUrl = await createCheckoutSession({checkoutPayload: submitObj})
              window.location.replace(stripeSessionUrl.data);
            }}
            
          >
          {({ errors, touched }) => (
            <Form style={{maxWidth: "500px", alignSelf: "center"}}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    border: "1px solid black",
                    alignContent: "center",
                    alignSelf: "center",
                    padding: "20px",
                    gap: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <h2>order form</h2>
                  
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <label
                      htmlFor="email"
                      style={{ width: "35%", textAlign: "left", fontWeight: "bold" }}
                    >
                      email:
                    </label>
                    <Field value={email} onChange={(event: any)=>{setEmail(event.target.value)}} type="email" id="email" name="email" style={{ flex: 1 }} required />
                    {errors.email && touched.email ? (
                      <div>{errors.email}</div>
                    ) : null}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <label
                      htmlFor="phone"
                      style={{ width: "35%", textAlign: "left", fontWeight: "bold" }}
                    >
                      phone:
                    </label>
                    <input value={phone} onChange={(event)=>{setPhone(event.target.value)}} type="tel" id="phone" name="phone" style={{ flex: 1 }}  required placeholder="718-123-1234"/>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <label
                      htmlFor="firstName"
                      style={{ width: "35%", textAlign: "left", fontWeight: "bold" }}
                    >
                      first name:
                    </label>
                    <input value={firstName} onChange={(event)=>{setFirstName(event.target.value)}} type="text" id="firstName" name="firstName" style={{ flex: 1 }} required />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <label
                      htmlFor="lastName"
                      style={{ width: "35%", textAlign: "left", fontWeight: "bold" }}
                    >
                      last name:
                    </label>
                    <input value={lastName} onChange={(event)=>{setLastName(event.target.value)}} type="text" id="lastName" name="lastName" style={{ flex: 1 }} required />
                  </div>

                  <hr />

                  {popups && popups[0] && _.sortBy(popups[0].items, ["id"]).map((lineItem: any) => {
                    return (
                      <div
                        id="lineItem"
                        style={{ display: "flex", flex: 1, flexDirection: "row" }}
                        key={lineItem.name}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>{lineItem.name}</span>
                          <span>{(lineItem.price_cents/100).toFixed(2)}</span>
                          {/* <span style={{ fontStyle: "italic" }}>
                            {lineItem.remaining_qty} / {lineItem.total_qty} remaining
                          </span> */}
                        </div>

                        <div>
                          <label
                            htmlFor="fname"
                            style={{
                              width: "35%",
                              textAlign: "left",
                              fontWeight: "bold",
                              marginRight: "10px",
                            }}
                          >
                            qty:
                          </label>

                          <input
                            type="number"
                            id="fname"
                            name="fname"
                            min={0}
                            style={{ width: "20%" }}
                            value={cartState[lineItem.id].qty}
                            onChange={(event) => {
                            const updatedValue =
                                event.target.value === ''
                                    ? 0
                                    : Number(event.target.value)
                            cartDispatch({
                                type: 'updateTicketQty',
                                payload: {
                                    popupItemId: lineItem.id,
                                    popupItemQty: updatedValue,
                                },
                            })
                        }}
                          />
                        </div>
                      </div>
                    );
                  })}

                  <hr />

                  <div>subtotal: ${(subtotal/100).toFixed(2)}</div>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <input
                      type="submit"
                      value="continue to payment"
                      style={{ flex: 1 }}
                    />
                  </div>
                </div>
          </Form>)}
          </Formik>) : (<><p><strong>Orders Closed</strong></p><p><strong>See you at the popup</strong></p></>)}
    </div>
  );
}

export default App;
