import axios, { AxiosInstance } from "axios";
import { config } from "../config";


const api: AxiosInstance = axios.create({
  baseURL: config.ynkoApiBaseURL,
  responseType: "json",
});

export const getPopUps = () => api.get(`/eatjerk/get-pop-ups`);

export const createCheckoutSession = ({ checkoutPayload }: any) =>
  api.post(`/eatjerk/checkout-session`, checkoutPayload);


export const getOrderWithSessionId = ({stripeSessionId}: any) => api.get(`/eatjerk/orders`,  { params: { stripe_session_id: stripeSessionId } });