interface AppConfig {
  baseURL: string;
  apiBaseURL: string;
  ynkoApiBaseURL: string;
}

const local: AppConfig = {
  baseURL: "http://localhost:3000",
  apiBaseURL: "http://localhost:8000/api",
  ynkoApiBaseURL: "http://localhost:8000/api",
};

const staging: AppConfig = {
  baseURL: "https://staging.eatjerknyc.com",
  apiBaseURL: "https://staging.ynko.app/api",
  ynkoApiBaseURL: "https://staging.ynko.app/api",
};

const production: AppConfig = {
  baseURL: "https://eatjerknyc.com",
  apiBaseURL: "https://ynko.app/api",
  ynkoApiBaseURL: "https://staging.ynko.app/api",
};

// eslint-disable-next-line import/no-mutable-exports
export let config: AppConfig = local;
const { hostname }: Location = window.location;
if (hostname === "eatjerknyc.com" || hostname === "www.eatjerknyc.com") {
  config = production;
} else if (hostname === "staging.eatjerknyc.com") {
  config = staging;
}
