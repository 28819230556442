import "./App.css";


function OrderError() {
  return (
    <div
      className="App"
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignContent: "center",
        alignSelf: "center",
        backgroundColor: "#f0f0f0",
      }}
    >
        <h2>EATJERKNYC</h2>
        <p>Sorry there was an error or we nuh 'av dat.</p>
        <p><a href="/">Try Again</a></p>
    </div>
  );
}

export default OrderError;
